.alert-message-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    visibility: hidden;
    opacity: 0;
    z-index: 100;

    transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
}

.alert-message-wrapper.opened {
    visibility: visible;
    opacity: 1;
}

.alert-message-container {
    position: relative;
    width: 40%;
    min-width: 320px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: overlay;
    border-radius: 20px;
    padding: 60px 10px;
    transform: scale(0);
    z-index: 100;

    transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
}

.alert-message-wrapper.opened .alert-message-container {
    transform: scale(1);
}

.alert-message-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.alert-message-container img {
    width: 40px;
    height: 40px;
    margin: 5px;
}

.alert-message-container h1 {
    font-family: 'Cabin' sans-serif;
    font-weight: bolder;
    font-size: 20px !important;
    margin: 10px;
    text-align: left;
}

.alert-message-container h2 {
    font-family: 'Cabin' sans-serif;
    font-weight: lighter;
    font-size: 24px !important;
    margin: 10px;
}

.alert-message-container button {
    padding: 15px 20px;
    min-width: 120px;
    background-color: #18406f;
    color: white;
    border-radius: 10px;
    border: none;
    outline: none;
    margin-top: 40px;
    font-size: 18px;
    font-family: 'Cabin' sans-serif;
    font-weight: bolder;
    cursor: pointer;

    transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
}

.alert-message-container button:hover {
    transform: translateY(-5px);
}

/*.alert-message-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    padding: 10px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;

    transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
}

.alert-message-close:hover {
    background-color: rgba(0, 0, 0, 0.3);
}*/