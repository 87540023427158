html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #18406f;
  border-radius: 50px;
}

body {
  padding: 0px;
  margin: 0px;
}

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  min-height: 100vh;
}

button.primary {
  padding: 12px;
  width: fit-content;
  min-width: 150px;
  outline: none;
  border-radius: 8px;
  border: none;
  font-family: 'Cabin', sans-serif;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  margin: 5px;
  color: #474747;

  transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
}

button.green-button {
  background-color: rgb(61, 170, 108);
  color: white;
}

button.red-button {
  background-color: rgb(170, 61, 61);
  color: white;
}

button.blue-button {
  background-color: #18406f;
  color: white;
}

button.primary:hover {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  transform: translateY(-4px);
}

input.primary {
  background-color: none;
  border-radius: 8px;
  border: 2px solid #F4F6FA;
  padding: 10px;
  margin: 5px;
  width: 50%;
  min-width: 250px;
  max-width: 500px;
  outline: none;
  font-size: 16px;
  font-family: 'Cabin', sans-serif;
  font-weight: 500;

  transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
}

input.search {
  border-radius: 50px;
  border-color: #F4F6FA;
  font-size: 14px;
}

input.form-field {
  border: none;
  border-bottom: 2px solid #F4F6FA;
  border-radius: 0px;
  margin: 8px 5px;
  font-size: 14px;
  color: #747474;
  min-width: 150px;
  flex: 1;
}

input.primary:focus {
  border-color: #5b82d7b9;
}

input.primary[type="submit"] {
  width: fit-content;
  min-width: 150px;
  background-color: #18406f;
  color: white;
  border: none;
  padding: 12px;
  cursor: pointer;
  margin-top: 15px;
}

input.primary[type="submit"]:hover {
  transform: translateY(-5px);
}

.MuiPickersToolbar-toolbar {
  background-color: #18406f !important;
}

.MuiPickersDay-daySelected {
  background-color: #18406f !important;
}

@font-face {
  font-family: 'Jersey';
  src: local('Jersey'), url(./pages/dashboard/assets/JerseyM54-aLX9.ttf) format('truetype');
  font-display: swap;
}