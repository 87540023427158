.register-wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.register-wrapper h1 {
    font-size: 30px;
    font-weight: 900;
    font-family: 'Cabin' sans-serif;
    margin: 20px 5px;
    color: #474747;
}

.register-wrapper h2 {
    font-size: 15px;
    font-weight: 500;
    font-family: 'Cabin' sans-serif;
    margin: 5px;
    margin-top: 40px;
    color: #747474;
}

.register-wrapper h3 {
    font-size: 15px;
    font-weight: 500;
    font-family: 'Cabin' sans-serif;
    margin: 5px;
    margin-bottom: 40px;
    color: #747474;
    cursor: pointer;
    border-bottom: 2px solid transparent;

    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
}

.register-wrapper h3:hover {
    border-bottom: 2px solid #747474;
}


.register-container {
    background-color: white;
    min-height: 500px;
    padding: 10px;
    width: 50%;
    max-width: 800px;
    min-width: 300px;
    /* margin: auto; */
    border: 2px solid #F4F6FA;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1); */
}

.register-container img {
    width: 150px;
}