.dashboard-wrapper {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 40px;
}

.dashboard-header {
    width: calc(100% - 20px);
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0px;
    margin: 0px 10px;
    border-bottom: 2px solid #5b82d713;
}

.dashboard-header img {
    /* width: 150px; */
    height: 120px;
}

.dashboard-header-details {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

.dashboard-header h1, .dashboard-header h2 {
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
    font-weight: 500;
    margin: 5px 10px;
    /* margin-left: auto; */
    padding: 10px 20px;
    border: 2px solid #18406f;
    border-radius: 50px;
    color: #18406f;
}

.dashboard-header h2 {
    border: 2px solid #7966cd;
    color: #7966cd;
    margin: 5px 15px;
}

.dashboard-header .logout-button {
    border-radius: 50%;
    padding: 10px;
    width: 45px;
    height: 45px;
    background-color: #18406f;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, .1);
    cursor: pointer;

    transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
}

.dashboard-header .back-button {
    position: absolute;
    left: 20px;
    border: 2px solid #18406f;
    background-color: transparent;
    box-shadow: none;
}

.dashboard-header .logout-button:hover {
    transform: translateY(-5px);
}

.dashboard-header .logout-button img {
    width: 100%;
    height: 100%;
    margin: 0px;
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(56deg) brightness(108%) contrast(102%);
}

.dashboard-header .back-button img {
    filter: invert(19%) sepia(30%) saturate(2890%) hue-rotate(189deg) brightness(93%) contrast(90%); /* 18406f */
}

.dashboard-utils {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 10px 0px;
}

.dashboard-history {
    max-width: calc(100% - 20px);
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
}

.dashboard-history-day {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 10px;
}

.dashboard-history-hour {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.dashboard-history-day h1 {
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #747474;
    margin: 0px;
}

.dashboard-history-day h1.day {
    margin: 0px;
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid #18406f;
    color: #18406f;
}

.dashboard-history-day h1.total {
    margin: 0px;
    width: max-content;
    height: 38px;
    padding: 0px 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 19px;
    border: 2px solid #18406f;
    background-color: #18406f;
    color: white;
}

.dashboard-history-day h2 {
    margin: 4px;
    font-family: 'Jersey', sans-serif;
    font-size: 22px;
    font-weight: 500;
    color: #474747;
}

.dashboard-container {
    width: 100%;
    flex: 1;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-top: 50px;
}

.dashboard-calender {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
}

.dashboard-details {
    width: 50%;
    min-width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.dashboard-details h1 {
    font-size: 25px;
    font-family: 'Cabin', sans-serif;
    font-weight: 700;
    color: #747474;
}

.dashboard-details-picker {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

.number-picker-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 10px;
}

.number-picker-wrapper h1 {
    font-family: Jersey;
    font-size: 30px;
    letter-spacing: 2px;
    color: #474747;
}

.number-picker {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    min-width: 300px;
    height: fit-content;
    padding: 10px;
    margin: 5px;
    border-radius: 80px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
}

.number-picker.disabled {
    justify-content: center;
}

.number-picker button {
    outline: none;
    border: none;
    background-color: transparent;
    padding: 5px 10px;
    height: 120px;
    border-radius: 15px 80px 80px 15px;
    cursor: pointer;

    transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
}

.number-picker button:hover {
    background-color: #f5f5f5;
}

.number-picker button:active {
    background-color: #e4e4e4;
}

.number-picker button:first-child {
    border-radius: 80px 15px 15px 80px;
}

.number-picker img {
    width: 50px;
    height: 50px;
    /* filter: invert(49%) sepia(0%) saturate(0%) hue-rotate(219deg) brightness(92%) contrast(92%); */
    filter: invert(28%) sepia(13%) saturate(0%) hue-rotate(239deg) brightness(94%) contrast(95%);
}

.number-picker h1 {
    font-size: 50px;
    font-family: Jersey;
    color: #747474;
}