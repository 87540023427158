.search-item-wrapper {
    width: 80%;
    max-width: 1000px;
    min-width: 300px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding: 0px 20px;
    cursor: pointer;

    transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;

    color: #474747;
}

.search-item-wrapper.header {
    cursor: unset;
    background-color: #18406f;
    color: white;
    border-radius: 15px;
    border-bottom: unset;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.search-item-wrapper:hover {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    padding-right: 0px;
}

.search-item-wrapper.header:hover {
    padding-right: 20px;
}

.search-item-wrapper h1 {
    font-size: 25px;
    font-family: 'Cabin', sans-serif;
    font-weight: 900;
}

.search-item-wrapper h2 {
    font-size: 20px;
    font-family: 'Cabin', sans-serif;
    font-weight: 500;
    margin-left: 40px;
    color: #747474;
}

.search-item-wrapper h3 {
    font-size: 22px;
    font-family: 'Jersey', sans-serif;
    font-weight: 500;
    margin: 0px 4px;
}

.search-item-wrapper.header h3 {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 1.5px;
}

.search-item-wrapper h4 {
    font-size: 20px;
    font-family: 'Cabin', sans-serif;
    font-weight: 400;
}

.search-item-wrapper div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}

.delete-button {
    margin-left: 10px !important;
    padding: 0px;
    width: 0px;
    height: 78px;
    background-color: #c70000bb;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1) inset;

    transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
}

.delete-button img {
    width: calc(100% - 10px);
    margin: 0px;
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(56deg) brightness(108%) contrast(102%);
}

.restore-button {
    background-color: #00c700bb;
}

.search-item-wrapper:hover .delete-button {
    padding: 10px;
    width: 60px;
}

.delete-button:hover {
    background-color: #c70000;
}

.restore-button:hover {
    background-color: #00c700;
}

@media only screen and (max-width: 1024px) {
    .search-item-wrapper {
        width: calc(100% - 40px);
        min-width: 300px;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 5px;
        border-radius: 10px;
    }

    .search-item-wrapper.header {
        flex-flow: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0px 20px;
    }
    
    .search-item-wrapper h1 {
        font-size: 22px;
    }
    
    .search-item-wrapper h2 {
        font-size: 18px;
        margin-left: unset;
    }

    .search-item-wrapper div {
        margin-left: unset;
    }

    .search-item-wrapper.header div {
        margin-left: auto;
    }

    .delete-button {
        padding: 10px;
        width: calc(100% + 2px);
        margin-left: 0px !important;
        border-radius: 0px 0px 10px 10px;
    }

    .search-item-wrapper:hover .delete-button {
        padding: 10px;
        width: calc(100% + 2px);
    }

    .delete-button img {
        width: 40px;
    }
}